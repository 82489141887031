import React from "react";
import classnames from "classnames";
import { BaseLayout, Footer } from "components";
import { isIOS, handleDownloadProcess, excuteDownload } from "utils";
import { linkConfig, webCfg } from "config";
import styles from "./styles.module.scss";
import { parse } from "qs";
const HiddenDownloadInfo = () => (
  <div id="download-info" style={{display: "none"}}>
    <span>pooling download urls</span>
    { linkConfig.download.android.anto.map((url, i) => <span id={`url-${i}`} key={i}>{url}</span>)}
    <span id={"backup-url"}>{linkConfig.download.android.anto_backup}</span>
  </div>
)
class Download extends React.PureComponent {
  state = {
    androidUrl: linkConfig.download.android.anto,
    androidUrlBackup: linkConfig.download.android.anto_backup || "",
    iosUrl: linkConfig.download.ios.anto,
    loading: false,
    title: "立即下载",
    slogan: "下载 汇鑫贵金属 APP",
    platform: "huixin",
    detail: "/download/detail",
  };
  componentDidMount() {
    if (window.location.search) {
      const params = parse(window.location.search.replace(/\?/, ""));
      if (
        params.platform &&
        Object.keys(linkConfig.download.ios).includes(params.platform)
      ) {
        this.setState({
          slogan: params.title,
          platform: params.platform,
          detail: `${this.state.detail}${window.location.search}`,
        });
      }
    }
  }
  startDownload = async() => {
    this.setState({
      loading: true,
      title: isIOS() ? "立即下载" : "获取中...",
    });
    let urls = this.state.androidUrl;
    let backUrl = this.state.androidUrlBackup;
    try {
      await handleDownloadProcess(urls);
    } catch(e) {
      backUrl && excuteDownload(backUrl);
    } finally {
      this.setState({
        loading: false,
        title: "立即下载",
      });
    }
  };
  render() {
    const ua = window.navigator.userAgent.toLowerCase();
    const isWeixinBrowser = !!ua.match(/MicroMessenger/i);
    return (
      <BaseLayout>
        <div className={classnames("full-screen", styles.download)}>
          {isWeixinBrowser ? (
            <div
              className={classnames(styles.shadow, {
                [styles.android]: !isIOS(),
              })}
            />
          ) : null}
          <h2>顶尖平台 卓越体验</h2>
          <p className={styles.summary}>操作简单易用，随时随地交易</p>

          <div
            className={styles.animate}
            style={{
              backgroundImage: `url("/download/logo.png")`,
            }}
          />
          <h3>{this.state.slogan} </h3>
          <p className={styles.strength}>
            <span>开放</span>
            <span>创新</span>
            <span>安全</span>
            <span>可信</span>
          </p>
          <div className={styles.download_wrapper}>
            <div className="flex-container flex-center">
              {isIOS() && (
                <div className={styles.ios_down}>
                  <a
                    href={this.state.iosUrl}
                    className={classnames(
                      "flex-block active",
                      styles.download_btn_store,
                      {
                        [styles.loading]: this.state.loading,
                      }
                    )}
                  >
                    <span className={styles.icon_ios}></span>
                    <span className={styles.text}>汇鑫贵金属</span>
                    <span className={styles.icon}></span>
                  </a>
                </div>
              )}
              {!isIOS() && (
                <div
                  onClick={this.startDownload}
                  className={classnames(
                    "flex-block active",
                    styles.download_btn,
                    {
                      [styles.loading]: this.state.loading,
                    }
                  )}
                >
                  {console.log(this.state)}
                  {this.state.title}
                </div>
              )}
            </div>
          </div>
          <HiddenDownloadInfo  />
          <Footer title={webCfg[this.state.platform].copyright} />
        </div>
      </BaseLayout>
    );
  }
}

export default Download;
